<template>
  <div id="cashCollection">
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Cash Collection") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="6">
          <div class="box">
            <h4 class="second-title">{{ $t("Select Payment") }}</h4>
            <ul class="payment-methods text-center">
              <li class="text-center disabled">
                <p class="icon" @click="selectPaymentMethod('Visa')">
                  <i class="fas fa-money-check"></i>
                </p>
                <p class="text">{{ $t("Visa") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Cash' ? 'active' : ''"
                  @click="selectPaymentMethod('Cash')"
                >
                  <i class="far fa-money-bill-alt"></i>
                </p>
                <p class="text">{{ $t("Cash") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Bank Transfer' ? 'active' : ''"
                  @click="selectPaymentMethod('Bank Transfer')"
                >
                  <i class="fas fa-dollar-sign"></i>
                </p>
                <p class="text">{{ $t("Bank Transfer") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Check' ? 'active' : ''"
                  @click="selectPaymentMethod('Check')"
                >
                  <i class="far fa-credit-card"></i>
                </p>
                <p class="text">{{ $t("Check") }}</p>
              </li>
            </ul>
          </div>
          <div class="selectInput">
            <label for="select payment" class="main-color">{{
              $t("Cash Point")
            }}</label>
            <v-select
              solo
              :items="paymentObject.cash_points"
              v-model="paymentObject.cash_point_id"
              :disabled="submitted"
              item-text="name"
              item-value="id"
              :rules="validationRules.cash_point"
            >
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="box">
            <v-row>
              <v-col cols="12" md="6">
                <p class="key">{{ $t("Transaction Id") }}</p>
                <p class="value">{{ paymentObject.transaction_id }}</p>
              </v-col>
              <v-col cols="10" md="5">
                <p class="key">{{ $t("Transaction Date") }}</p>
                <v-text-field
                  id="transaction_date"
                  autocomplete="off"
                  v-model="paymentObject.transaction_date"
                  solo
                  dense
                  @keydown.prevent
                  :disabled="submitted"
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="1">
                <v-icon
                  medium
                  style="float: right; opacity: 0.5; margin-top: 40px"
                  class="mr-2 main-color dateIcon"
                  >event</v-icon
                >
              </v-col>
              <v-row v-if="paymentMethod == 'Cash'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Voucher Number") }}</p>
                  <p class="value">{{ paymentObject.voucher_number }}</p>
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Bank Transfer'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Transfer Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.transfer_number"
                    solo
                    dense
                    :rules="validationRules.transfer_number"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0">
                  <p class="key">{{ $t("Transfer Date") }}</p>
                  <v-text-field
                    id="transfer_date"
                    autocomplete="off"
                    v-model="paymentObject.transfer_date"
                    solo
                    dense
                    :rules="validationRules.transfer_date"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right; opacity: 0.5; margin-top: 28px"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Check'">
                <v-col cols="12" md="6" style="padding-top: 0">
                  <p class="key">{{ $t("Check Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.check_number"
                    solo
                    dense
                    :rules="validationRules.check_number"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0">
                  <p class="key">{{ $t("Check Date") }}</p>
                  <v-text-field
                    id="check_date"
                    autocomplete="off"
                    v-model="paymentObject.check_date"
                    solo
                    dense
                    :rules="validationRules.check_date"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right; opacity: 0.5; margin-top: 28px"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
            </v-row>
          </div>
          <!-- <div class="textAreaInput">
            <label for="textarea">Notes</label>
            <v-textarea
              rows="2"
              v-model="paymentObject.notes"
              row-height="5"
              :disabled="submitted"
              solo
            ></v-textarea>
          </div> -->
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="2">
        <h3 class="second-title">{{ $t("Refund For") }}</h3>
      </v-col>
      <v-col cols="12" md="10" class="info-list">
        <v-row>
          <v-col cols="6" md="2">
            <p class="value">
              <strong>{{ $t("Payment Type") }}</strong>
            </p>
          </v-col>
          <v-col cols="6" md="2">
            <p class="value">
              <strong>{{ $t("Transaction ID") }}</strong>
            </p>
          </v-col>
          <v-col cols="6" md="2">
            <p class="value">
              <strong>{{ $t("Transaction Date") }}</strong>
            </p>
          </v-col>
          <v-col
            cols="6"
            md="2"
            :hidden="
              transactionObject.type == 'Cash' ||
              transactionObject.type == 'onlinePayment'
            "
          >
            <p class="value">
              <strong v-if="transactionObject.type == 'Bank Transfer'">{{
                $t("Transfer Date")
              }}</strong>
              <strong v-if="transactionObject.type == 'Check'">{{
                $t("Check Date")
              }}</strong>
            </p>
          </v-col>
          <v-col cols="6" md="2">
            <p class="value">
              <strong v-if="transactionObject.type == 'Bank Transfer'">{{
                $t("Transfer Number")
              }}</strong>
              <strong v-if="transactionObject.type == 'Check'">{{
                $t("Check Number")
              }}</strong>
              <strong
                v-if="
                  transactionObject.type == 'Cash' ||
                  transactionObject.type == 'onlinePayment'
                "
                >{{ $t("Voucher Number") }}</strong
              >
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="2" style="padding-top: 0">
            <p class="value">
              {{ transactionObject.type }}
            </p>
          </v-col>
          <v-col cols="6" md="2" style="padding-top: 0">
            <p class="value">
              {{ transactionObject.transaction_id }}
            </p>
          </v-col>
          <v-col cols="6" md="2" style="padding-top: 0">
            <p class="value">
              {{ transactionObject.transaction_date }}
            </p>
          </v-col>
          <v-col
            cols="6"
            md="2"
            style="padding-top: 0"
            :hidden="
              transactionObject.type == 'Cash' ||
              transactionObject.type == 'onlinePayment'
            "
          >
            <p class="value">
              {{ transactionObject.reference_date }}
            </p>
          </v-col>
          <v-col cols="6" md="2" style="padding-top: 0">
            <p class="value">
              {{ transactionObject.reference_no }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <h3 class="second-title">{{ $t("Payment Details") }}</h3>
      </v-col>
      <v-col cols="12" md="10" class="info-list">
        <v-row v-if="applicant_details">
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Name") }}:</strong>
              {{ applicant_details.applicant_name }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Partner Group") }}:</strong>
              {{ applicant_details.partner_group }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("ID") }}:</strong>
              {{ applicant_details.applicant_code }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Invoice Number") }}:</strong>
              {{ invoice.invoice_number }}
            </p></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <hr class="basic" />

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="items"
          :items-per-page="5"
          class="elevation-1 level1"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:item.counter="{ item }">
            <span>{{ items.indexOf(item) + 1 }}</span>
          </template>

          <template v-slot:item.due_date="{ item }">
            <v-icon
              v-if="item.type != 'tax'"
              color="#ff8972"
              class="calendar-icon"
              >calendar_today</v-icon
            >
            <span class="due-date">{{ item.due_date }}</span>
          </template>

          <template v-slot:item.due_amount="{ item }">
            <span v-if="item.type == 'tax'">{{ item.due_amount }}%</span>
            <span v-if="item.type != 'tax'">{{ item.due_amount }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="12" md="2">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ totalAmountBeforTax }}
        </p>
      </v-col>
      <v-col cols="12" md="2">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong> {{ taxAmount }}
        </p>
      </v-col>
      <v-col cols="12" md="2">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ totalAfterDiscount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Amount Paid") }}: </strong>
          {{ totalAmountPaid }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <!-- <v-btn @click="savePayment" color="#7297FF" v-if="!submitted"
          >Cancel</v-btn
        > -->
        <v-btn @click="openReasonDialog" color="#7297FF" v-if="!submitted">{{
          $t("Save Refund")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>

    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ $t("Confirmation Message") }}
        </v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to refund this transaction?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="confirmDialog = false">
            {{ $t("No") }}
          </v-btn>
          <v-btn @click="saveRefund()" class="modal-btn-save"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reasonDialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline"> {{ $t("Select Refund Reason") }} </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid2" ref="form2" :lazy-validation="lazy">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label> {{ $t("Refund Reason") }} </label>
                  <v-select
                    :items="[{ title: 'Others', value: 'Other' }]"
                    item-text="title"
                    item-value="value"
                    value="Other"
                    solo
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Please enter reason") }}</label>
                  <v-textarea
                    v-model.trim="paymentObject.notes"
                    :rules="validationRules.notes"
                    solo
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="space">
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="reasonDialog = false">
            {{ $t("Close") }}
          </v-btn>
          <v-btn
            :hidden="approve_excuses"
            class="modal-btn-save"
            @click="openConfirmDialog"
          >
            {{ $t("Next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Invoice",
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "counter",
        },
        { text: this.$i18n.t("Fee Type"), value: "item", sortable: false },
        {
          text: this.$i18n.t("Due Amount"),
          value: "due_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Due Date"), value: "due_date", sortable: false },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        { text: this.$i18n.t("Amount"), value: "amount", sortable: false },

        {
          text: this.$i18n.t("Net After Discount"),
          value: "net_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Amount Paid"),
          value: "amount_paid",
          sortable: false,
        },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        // {
        //   text: "Remaining Amount",
        //   value: "remaining_amount",
        //   sortable: false
        // }
      ],
      items: [],
      paymentObject: {},
      transactionObject: {},
      options: {
        itemsPerPage: 100,
      },
      transactionId: "",
      invoice_id: "",
      applicant_details: {},
      invoice: {},
      selected_Items: [],
      invoiceData: {},
      submitted: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      paymentMethod: "Cash",
      validationRules: {
        transfer_number: [(v) => !!v || "Transfer number is required"],
        transfer_date: [(v) => !!v || "Transfer date is required"],
        check_number: [(v) => !!v || "Check number is required"],
        check_date: [(v) => !!v || "Check date is required"],
        cash_point: [(v) => !!v || "Cash point is required"],
        notes: [(v) => !!v || "Refund reason is required"],
      },
      valid: true,
      valid2: true,
      lazy: false,
      confirmDialog: false,
      reasonDialog: false,
      approve_excuses: false,
    };
  },
  computed: {
    totalAmountBeforTax: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (item.type == "item") {
            num += Number(item.due_amount);
          }
        });

        return num;
      },
    },
    totalAmountForTaxable: {
      get() {
        let num = 0;
        this.items.forEach((item) => {
          if (
            item.type == "item" &&
            (item.taxable == 1 || item.taxable == true)
          ) {
            num += Number(item.due_amount);
          }
        });

        return num;
      },
    },
    taxPercentage: {
      get() {
        let tax = 0;
        this.items.forEach((item) => {
          if (item.type == "tax") {
            tax += Number(item.due_amount);
          }
        });

        return tax;
      },
    },
    taxAmount: {
      get() {
        return (this.taxPercentage * this.totalAmountForTaxable) / 100;
      },
    },
    total: {
      get() {
        return (
          this.totalAmountBeforTax +
          (this.taxPercentage * this.totalAmountForTaxable) / 100
        );
      },
    },
    totalAfterDiscount: {
      get() {
        let totalDisount = this.total;
        this.items.forEach((item) => {
          if (item.discount != "" && item.discount != undefined) {
            totalDisount -= item.discount;
          }
        });
        return totalDisount;
      },
      // setter
      set: function (newValue) {
        this.totalAfterDiscount = newValue;
      },
    },
    totalAmountPaid: {
      get() {
        let totalPaid = 0;
        this.items.forEach((item) => {
          // console.log(item);
          if (item.amount_paid != "" && item.amount_paid != undefined) {
            totalPaid += Number(item.amount_paid);
          }
        });
        return totalPaid;
      },
      // setter
      set: function (newValue) {
        this.totalAmountPaid = newValue;
      },
    },
  },
  watch: {
    items: {
      handler() {
        this.items.forEach((item) => {
          if (item.type != "tax" && !item.taxable) {
            item.net_amount = Number(item.amount) - Number(item.discount);
            // item.amount_paid = item.amount - item.discount;
          }
          if (item.type != "tax" && item.taxable) {
            let tax = 0;
            this.items.forEach((tax_item) => {
              if (tax_item.type == "tax") {
                tax += (item.due_amount * tax_item.due_amount) / 100;
              }
            });
            item.net_amount =
              Number(item.due_amount) + tax - Number(item.discount);
            // item.amount_paid =
            //   item.amount +
            //   (item.amount * this.items[this.items.length - 1]["due_amount"]) /
            //     100 -
            //   item.discount;
            item.amount = Number(item.due_amount) + tax;
          }
        });
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromApi(pageNumber);
      },
      deep: true,
    },
  },
  methods: {
    openReasonDialog() {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        this.reasonDialog = true;
      }
    },
    openConfirmDialog() {
      if (!this.valid2) {
        this.$refs.form2.validate();
      } else {
        this.reasonDialog = false;
        this.confirmDialog = true;
      }
    },
    selectPaymentMethod(method) {
      if (!this.submitted) {
        this.$refs.form.resetValidation();
        this.paymentMethod = method;
        var _this = this;
        if (this.paymentMethod == "Bank Transfer") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          $(document).ready(function () {
            $("#transfer_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.transfer_date = dateH;
                } else {
                  _this.paymentObject.transfer_date = "";
                }
              },
            });
          });
          axios
            .get(this.getApiUrl + "/payments/getCashPoints/Transfer", {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        } else if (this.paymentMethod == "Check") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          $(document).ready(function () {
            $("#check_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.check_date = dateH;
                } else {
                  _this.paymentObject.check_date = "";
                }
              },
            });
          });
          axios
            .get(this.getApiUrl + "/payments/getCashPoints/Check", {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        } else if (this.paymentMethod == "Cash") {
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          axios
            .get(this.getApiUrl + "/payments/getCashPoints/Cash", {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
            });
        }
      }
    },
    getPaymentInvoiceItems() {
      axios
        .get(this.getApiUrl + "/payments/getInvoiceId/" + this.transactionId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.invoice_id = response.data.data.invoice_id;
          axios
            .get(
              this.getApiUrl +
                "/invoices/getInvoiceData/" +
                this.invoice_id +
                "/" +
                this.transactionId +
                "/refund",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.items = response.data.data.items;
              this.invoiceData = response.data.data;
              this.applicant_details = response.data.data.applicant_datails;
              this.invoice = response.data.data.invoice;

              this.items.forEach((item) => {
                if (this.selected_Items.includes(item.item_id) == false) {
                  if (item.type == "tax") {
                    this.taxPercentage = item.due_amount;
                  }
                }
              });
            });
        });
    },

    getPaymentData() {
      axios
        .get(this.getApiUrl + "/payments/getRefundData/" + this.transactionId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.paymentObject = response.data.data.payment;
          this.transactionObject = response.data.data.transaction;
          this.$refs.form.resetValidation();
        });
    },

    saveRefund() {
      this.confirmDialog = false;
      if (this.paymentMethod != "Cash") {
        this.paymentObject.voucher_number = null;
      }
      axios
        .post(
          this.getApiUrl + "/payments/store/refund/" + this.transactionId,
          {
            payment: this.paymentObject,
            type: this.paymentMethod,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.submitted = true;
            this.snack = true;
            this.snackColor = "success";
            if (this.currentAppLocale == "ar") {
              this.snackText = "تمت معالجة الاسترداد بنجاح";
            } else {
              this.snackText = "Refund has been processed successfully";
            }
          } else {
            this.snack = true;
            this.snackColor = "error";
            if (this.currentAppLocale == "ar") {
              this.snackText = "حدث خطأ";
            } else {
              this.snackText = "An error has occurred";
            }
          }
        });
    },
    checkPaymentOnInvoice() {
      axios
        .get(
          this.getApiUrl +
            "/payments/checkRefundOnTransaction/" +
            this.transactionId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.data.hasRefund) {
            this.$router.push({
              path: "/payments",
            });
          }
        });
    },
  },
  mounted() {
    var _this = this;
    $(document).ready(function () {
      $("#transaction_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function (date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.paymentObject.transaction_date = dateH;
          } else {
            _this.paymentObject.transaction_date = "";
          }
        },
      });
    });

    this.transactionId = this.$router.currentRoute.params.transactionId;
    this.checkPaymentOnInvoice();
    this.getPaymentInvoiceItems();
    this.getPaymentData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
